import { useEffect } from 'react';
import PropTypes from 'prop-types';

const LoadScript = (props) => {
  const loadScript = () => {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = props.script;

    // Once the Google API Client is loaded, you can run your code
    script.onload = (e) => {
      // Initialize the Google API Client with the config object
      if (props.onLoad) {
        props.onLoad(e);
      }
    };
    // Add to the document
    document.body.appendChild(script);
  };
  useEffect(() => {
    loadScript();
  }, []);
  return null;
};

LoadScript.propTypes = {
  onLoad: PropTypes.func,
  script: PropTypes.string,
};

export default LoadScript;
