import React from 'react';

const ScoreView = (props) => {
    const renderStars = (score) => {
        let stars = [];
        for(let i = 1; i<=5; i++){
            console.log(`ReviewScore i ${i} score ${score} floor ${Math.ceil(score)} full? ${i<score} half? ${i < Math.ceil(score)}`);
            if(i <= score){
                stars.push(<span className="full-star">&#9733;</span>);
            }else if(i <= Math.ceil(score)){
                stars.push(<span className="half-star">&#9734;</span>);
            }else{
                stars.push(<span className="empty-star">&#9900;</span>);
            }
        }
        return stars;
    }
    return <div className="score-view">
        {renderStars(props.score)}
    </div>
}

export default ScoreView;