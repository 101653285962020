import React, { useState, useEffect } from 'react';
import Hero from './Hero/Hero';
import About from './About/About';
import Projects from './Projects/Projects';
import Price from './Price/Price';
import Contact from './Contact/Contact';
import Location from './Location/Location';
import Reviews from './Reviews/Reviews';
import Footer from './Footer/Footer';
import Schema from './Schema/Schema.jsx';
import "fontsource-roboto"; // Defaults to weight 400 with all styles included.
import "fontsource-limelight"; // Defaults to weight 400 with all styles included.
import Seo from './Seo/index';

import { PortfolioProvider } from '../context/context';

import {
  heroData,
  aboutData,
  projectsData,
  contactData,
  footerData,
  locationData,
  reviewData,
  priceData,
} from '../mock/data';

function App() {
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [projects, setProjects] = useState([]);
  const [price, setPrice] = useState({});
  const [location, setLocation] = useState({});
  const [reviews, setReviews] = useState([]);
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setProjects([...projectsData]);
    setPrice({ ...priceData });
    setLocation({ ...locationData });
    setReviews([ ...reviewData ]);
    setContact({ ...contactData });
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ hero, about, projects, contact, footer, location, price, reviews }}>
      <Seo title="Home Page" />
      <Hero isDesktop={isDesktop} isMobile={isMobile} />
      <About isDesktop={isDesktop} isMobile={isMobile} />
      <Projects isDesktop={isDesktop} isMobile={isMobile} />
      <Price isDesktop={isDesktop} isMobile={isMobile} />
      <Reviews isDesktop={isDesktop} isMobile={isMobile} />
      <Schema />
      <Location isDesktop={isDesktop} isMobile={isMobile} />
      <Contact isDesktop={isDesktop} isMobile={isMobile} />
      <Footer isDesktop={isDesktop} isMobile={isMobile} />
    </PortfolioProvider>
  );
}

export default App;
