import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import ProjectImg from '../Image/ProjectImg';
import ScoreView from './ScoreView';

const Projects = ({ isDesktop, isMobile }) => {
  const { reviews } = useContext(PortfolioContext);
  console.log(`Reviews props reviews`, reviews);
  return (
    <section id="reviews">
      <Container>
        <div className="review-wrapper">
          <Title title="Reviews" />

          {reviews.map(({text, name, score}, i) => {

            return (
                <Fade
                    top
                    duration={1000}
                    delay={500}
                    distance="30px"
                >
                  <Row key={i} className="review">
                    <div className="review__block">
                        <Fade
                            top
                            duration={1000}
                            delay={1000}
                            distance="30px"
                        >
                            <ScoreView score={score} />
                        </Fade>
                        <Fade
                            bottom
                            duration={1000}
                            delay={1000}
                            distance="30px"
                        >
                            <p className="review__text">
                                "{text}"  
                            </p>
                            <p className="mb-4 review__name">- {name || ''}</p>
                        </Fade>
                      </div>
                </Row>
              </Fade>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default Projects;
