import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, metaImage, title, pathname }) {
  const query = useStaticQuery(
    graphql`
      query SeoQuery {
        site {
          ...SiteInformation
        }
        logo: file(relativePath: { eq: "twitter.png" }) {
            childImageSharp {
              # Specify the image processing specifications right in the query.
              # Makes it trivial to update as your page's design changes.
              fixed(width: 1200, height: 675) {
                ...GatsbyImageSharpFixed
              }
            }
          }
      }
    `
  )
  console.log(`SEO query`, query);
  const site = query.site;
  const metaDescription = description || site.siteMetadata.description
  let image;
  if(metaImage && metaImage.src){
    image = metaImage;
  }else{
    image = {
        src: site.siteMetadata.siteUrl + query.logo.childImageSharp.fixed.src,
        width: 1200,
        height: 675
    }
  }
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={site.siteMetadata.title}
      titleTemplate={site.siteMetadata.title}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "keywords",
          content: site.siteMetadata.keywords.join(","),
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
            [
                {
                  property: "og:image",
                  content: image.src,
                },
                {
                  property: "og:image:width",
                  content: image.width,
                },
                {
                  property: "og:image:height",
                  content: image.height,
                },
                {
                  name: "twitter:card",
                  content: image.width >= 600?"summary_large_image":"summary",
                },
            ]
        )
        .concat(meta)}
    />
  )
}
SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}
SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
}
export default SEO