import React, {useContext} from 'react';
import PortfolioContext from '../../context/context';

const Schema = () => {
    const { reviews } = useContext(PortfolioContext);

    const getSchema = () => {
        // return JSON.stringify({
        //     "type": "website",
        //     "about": "Phone coaching services in Warragul and surrounding areas",
        //     "accessMode": "textual",
        //     "contentLocation": "Warragul",
        //     "dateCreated": "2021-02-23",
        //     "headline": "Phone coaching services in Warragul and surrounding areas",
        //     "isFamilyFriendly": true, 
        //     "keywords": "phone, tutoring, coaching, internet, social media, practice, local, computer, tablet, mobile phone"
        // })
        return JSON.stringify({
            "type": "service",
            "areaServed": "Warragul",
            "category":"education",
            "description": "Phone coaching services in Warragul and surrounding areas",
            "dateCreated": "2021-02-23",
            "headline": "Phone coaching services in Warragul and surrounding areas",
            "isFamilyFriendly": true, 
            "keywords": "phone, tutoring, coaching, internet, social media, practice, local, computer, tablet, mobile phone",
            "hoursAvailable": {
                "type": "OpeningHoursSpecification",
                "dayOfWeek": "monday, tuesday, wednesday, thursday, friday, saturday",
                "opens": "8am",
                "closes": "6pm"
            },
            "slogan": "Phone Coaching Practice Makes Perfect",
            "reviews": reviews.map(e => {
                return {
                    "itemReviewed": "Phone coaching",
                    "reviewBody": e.text,
                    "reviewRating": e.score
                }
            })
        })
    }
    return <div id="schema">
        {getSchema()}
    </div>;
}

export default Schema;