import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';
import PropTypes from 'prop-types';
import PhoneCoach from './../../images/phonecoach.svg';
import PhoneBanner from './../../images/phoneBanner.svg';

const Header = ({ isDesktop, isMobile }) => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, cta } = hero;

  return (
    <section id="hero" className="jumbotron">

        
       <div className="hero-heading-container" style={{opacity: 0, position: 'absolute'}}>
          
          <p className="hero-subheading">Your Local</p>
          <h1 className="hero-heading text-color-title">
            Phone Coach
          </h1>
        </div>
      <Container>
      <Fade left={isDesktop} bottom={isMobile} duration={500} delay={0} distance="30px">
        <div className="hero-image">
        <div className="hero-bg" style={{
          background: `url(${PhoneBanner}) no-repeat`, backgroundSize: '100% auto', transform: 'rotate(-5deg)', transformOrigin: '50% 20%', 
          width: '100%', height:'100%'}} />
        </div>
       </Fade>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
          <h2 className="hero-title">
            {title || 'Hi, my name is'}{' '}
            <span className="text-color-main">{name || 'Your Name'}</span>
            <br />
            {subtitle || "I'm the Unknown Developer."}
          </h2>
        </Fade>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
          <p className="hero-cta">
            <span className="cta-btn cta-btn--hero cta-btn--orange">
              <Link to="about" smooth duration={1000}>
                {cta || 'Know more'}
              </Link>
            </span>
          </p>
        </Fade>
      </Container>
    </section>
  );
};

Header.propTypes = {
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default Header;
