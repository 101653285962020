import React, { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import GoogleMaps from './../GoogleMaps/GoogleMaps';

const Location = ({ isDesktop, isMobile }) => {
  const { location } = useContext(PortfolioContext);
  const { text } = location;

  return (
    <section id="location">
      <Container>
        <Title title="Location" />
        <Row className="location-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="location-wrapper__map">
                <GoogleMaps />
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="location-wrapper__text">
                {text &&
                  text.map((e) => {
                    return <p>{e}</p>;
                  })}
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Location;
