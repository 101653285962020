import React from 'react';
import validate from 'validate.js';

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const VALIDATION_REQUIREMENTS = {
  email: {
    email: true,
    presence: true,
  },
  name: {
    length: { maximum: 50, minimum: 1 },
    presence: true,
  },
  message: {
    presence: true,
  },
};

export default function Contact() {
  const [state, setState] = React.useState({});
  const [sent, setSent] = React.useState(false);
  const [errors, setErrors] = React.useState(null);

  const handleChange = (e) => {
    const nForm = { ...state, [e.target.name]: e.target.value };
    setState(nForm);
  };
  const validateForm = (form) => {
    const err = validate(form, VALIDATION_REQUIREMENTS);
    console.log(`Form validateForm err`, err);
    console.log(`Form validateForm form`, form);
    setErrors(err);
    return err;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(`Form handleSubmit state`, state);
    const form = e.target;
    if (!validateForm(state)) {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...state,
        }),
      })
        .then(() => {
          // navigate(form.getAttribute('action'))
          setSent(true);
        })
        .catch((error) => alert(error));
    }
  };
  const renderErrorMessages = (messages) => {
    return messages.map((e) => {
      return <p>{e}</p>;
    });
  };
  const renderErrors = (err) => {
    if (err) {
      let v = [];
      if (err.message) v = [...v, ...renderErrorMessages(err.message)];
      if (err.name) v = [...v, ...renderErrorMessages(err.name)];
      if (err.email) v = [...v, ...renderErrorMessages(err.email)];
      return (
        <div className="form__error">
          <p>Errors:</p>
          {v}
        </div>
      );
    }
  };
  if (sent) {
    return (
      <>
        <h1>Your message has been sent!</h1>
        <p>I'll get in touch with you in the next few days.</p>
      </>
    );
  }
  return (
    <section id="form">
      {renderErrors(errors)}
      <form
        name="contact"
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
          </label>
        </p>
        <p>
          <label className="form__label">
            Your name:
            <br />
            <input type="text" name="name" onChange={handleChange} className="form__input" />
          </label>
        </p>
        <p>
          <label className="form__label">
            Your email:
            <br />
            <input type="email" name="email" onChange={handleChange} className="form__input" />
          </label>
        </p>
        <p>
          <label className="form__label">
            Message:
            <br />
            <textarea name="message" onChange={handleChange} className="form__input" />
          </label>
        </p>
        {/* <p>
          <button type="submit" className="cta-btn--hero">Send</button>
        </p> */}
        <p className="hero-cta cta-btn-container">
          <button type="submit" className="form__submit cta-btn cta-btn--hero cta-btn--purple">
            Send
          </button>
        </p>
      </form>
    </section>
  );
}
