import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { Container, Row } from 'react-bootstrap';
import Title from '../Title/Title';
import PortfolioContext from '../../context/context';

const About = ({ isDesktop, isMobile }) => {
  const { about } = useContext(PortfolioContext);
  const { paragraphZero, paragraphOne, paragraphTwo, paragraphThree, resume } = about;

  return (
    <section id="about">
      <Container>
        <Title title="What do I help with?" />
        <Row className="about-wrapper">
          <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
            <div className="about-wrapper__info">
              <p className="about-wrapper__info-text">
                {paragraphZero ||
                  'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi neque, ipsa animi maiores repellendu distinctioaperiam earum dolor voluptatum consequatur blanditiis inventore debitis fuga numquam voluptate architecto itaque molestiae.'}
              </p>
              <p className="about-wrapper__info-text">
                {paragraphOne ||
                  'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi neque, ipsa animi maiores repellendu distinctioaperiam earum dolor voluptatum consequatur blanditiis inventore debitis fuga numquam voluptate architecto itaque molestiae.'}
              </p>
              <p className="about-wrapper__info-text">
                {paragraphTwo ||
                  'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi neque, ipsa animi maiores repellendu distinctioaperiam earum dolor voluptatum consequatur blanditiis inventore debitis fuga numquam voluptate architecto itaque molestiae.'}
              </p>
              <p className="about-wrapper__info-text">
                {paragraphThree || 'Lorem ipsum dolor sit, amet consectetur adipisicing elit.'}
              </p>
              {resume && (
                <span className="d-flex mt-3">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cta-btn cta-btn--resume"
                    href={resume}
                  >
                    Resume
                  </a>
                </span>
              )}
            </div>
          </Fade>
        </Row>
      </Container>
    </section>
  );
};

About.propTypes = {
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default About;
