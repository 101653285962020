import React, {useState, useEffect} from 'react';
import {Link} from 'gatsby';
import Fade from 'react-reveal/Fade';

const Privacy = ({isDesktop, isMobile}) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        console.log(`privacy click`);
        setOpen(true);
    }
    const handleClose = () => {
        console.log(`privacy click close`);
        setOpen(false);
    }
    return <div id="privacy" >
        <div className={`privacy-container ${open?'privacy-container__open':'privacy-container__closed'}`}>
            <div className={`privacy-container__modal`}>
                <span className="back-to-top">
                    <button  className="privacy-container__button" onClick={handleClose}>
                    <i className="fa fa-angle-up fa-2x" aria-hidden="true" />
                    </button>
                </span>
                <h1>Privacy Policy</h1>
                <p>This website collects anonymous data on how it is used</p>
                <p>Any details given in the contact forms are used for contact purposes only</p>
                <p>None of your details are sold to third party vendors</p>
            </div>
        </div>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
        <button className="cta-btn cta-btn--hero cta-btn--orange privacy-button" onClick={handleOpen}>Privacy Policy</button>
        </Fade>
        
    </div>
}

export default Privacy;

