import React, { useEffect, useState } from 'react';
import LoadScript from './../LoadScript/LoadScript';
import ProjectImg from '../Image/ProjectImg';
const GoogleMaps = (props) => {
  const initMap = () => {
    // The location of Uluru

    const warragul = { lat: -38.1538, lng: 145.9298 }; //38.1538° S, 145.9298
    const halfway = { lat: -38.1445, lng: 145.889 };
    const drouin = { lat: -38.1354, lng: 145.8487 }; //38.1354° S, 145.8487° E
    // The map, centered at Uluru
    const map = new google.maps.Map(document.getElementById('map'), {
      zoom: 12,
      center: halfway,
    });
    // The marker, positioned at Uluru
    new google.maps.Marker({
      position: warragul,
      map: map,
    });
    new google.maps.Marker({
      position: drouin,
      map: map,
    });
    // new google.maps.Marker({
    //     position: drouin,
    //     map: map,
    // });
  };
  const onLoad = (ev) => {
    console.log(`GoogleMaps onLoad script`, ev);
    console.log(`GoogleMaps onLoad window.google.maps`, window.google.maps);
    initMap();
  };
  return (
    <React.Fragment>
      <div id="map" style={{ width: '100%', height: '100%' }} />
      <LoadScript
        // apiKey="AIzaSyAhmY9N7bOWZlIpkshz2EhlSyn4ipya8xY"
        // //script='https://maps.googleapis.com/maps/api/js?key=AIzaSyAhmY9N7bOWZlIpkshz2EhlSyn4ipya8xY&callback=initMap'
        // script="https://maps.googleapis.com/maps/api/js?key=AIzaSyAhmY9N7bOWZlIpkshz2EhlSyn4ipya8xY"
        apiKey="AIzaSyA2JKUPErzwyAm3YkxL17R4CLZQIhRwOOE"
        script="https://maps.googleapis.com/maps/api/js?key=AIzaSyA2JKUPErzwyAm3YkxL17R4CLZQIhRwOOE"
        onLoad={onLoad}
      />
    </React.Fragment>
  );
};
// const GoogleMaps = (props) => {
//   return (
//     <ProjectImg alt={'Map of Warragul and surrounding areas'} filename={'map.png'} />
//   );
// };

export default GoogleMaps;
