import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';
import GoogleMaps from './../GoogleMaps/GoogleMaps';

const Price = ({ isDesktop, isMobile }) => {
  const { price } = useContext(PortfolioContext);

  return (
    <section id="price">
      <Container>
        <Title title="How much does this service cost?" />
        <Row className="price-wrapper">
          <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
            <div className="price">
              <h4 className="price__title">${price.price} per hour</h4>
            </div>
          </Fade>
        </Row>
      </Container>
    </section>
  );
};

export default Price;
